import React, { useMemo, useState } from 'react';
import { useConnectProject } from '@hooks/workspace/connectIntegration';
import { ExternalLink, Users } from 'react-feather';
import { ConnectIntegrationType } from '@features/Platform/Integrations';
import { colors } from '@styles';
import { Badge } from '@kit/ui/Badge';
import { enumToHuman } from '@utils';
import {
  getConnectContractLink,
  getConnectProjectLink,
  getConnectProposalLink
} from '@features/Platform/Integrations/const';
import { ConnectIntegrationBrand } from '@features/Platform/Integrations/Common/ConnectIntegrationBrand/ConnectIntegrationBrand';
import { DateTime } from 'luxon';
import { Cell, CellBotton, CellTop, List, Row, Tab, Tabs } from './styled';
import { Widget, WidgetHeader, WidgetIcon, WidgetTitle } from '../styled';

interface Props {
  projectId: number;
  isFullWidth?: boolean;
  title?: string;
}

export const Sales: React.FC<Props> = ({ title = 'Sales', projectId, isFullWidth = false }) => {
  const [activeTab, setActiveTab] = useState('projects');

  const { data: log } = useConnectProject({ projectId });

  const externalProject = useMemo(
    () =>
      (log?.raw?.project &&
        log.integration.type === ConnectIntegrationType.AURORA_SOLAR && {
          id: log.raw.project.id,
          name: log.raw.project.name,
          created_at: log.raw.project.created_at,
          status: log.raw.project.status
        }) ||
      (log?.raw?.project &&
        log.integration.type === ConnectIntegrationType.OPEN_SOLAR && {
          id: log.raw.project.id,
          name: log.raw.project.address,
          created_at: DateTime.fromISO(log.raw.project.created_date).toLocaleString(DateTime.DATETIME_MED),
          status: log.raw.project.status_message
        }) ||
      null,
    [log]
  );

  const externalProposal = useMemo(
    () =>
      (log?.raw?.design &&
        log.integration.type === ConnectIntegrationType.AURORA_SOLAR && {
          design_id: log.raw.design.design_id,
          name: log.raw.design.name,
          created_at: log.raw.design.created_at,
          status: log.raw.design.milestone?.milestone
        }) ||
      (log?.raw?.system &&
        log.integration.type === ConnectIntegrationType.OPEN_SOLAR && {
          design_id: log.raw.system.id,
          name: log.raw.system.name,
          created_at: log.raw.system?.pricing_scheme_data
            ? DateTime.fromISO(log.raw.system.pricing_scheme_data).toLocaleString(DateTime.DATETIME_MED)
            : null,
          status: 'sold'
        }) ||
      null,
    [log]
  );

  const externalContract = useMemo(
    () =>
      (log?.raw?.agreement &&
        log.integration.type === ConnectIntegrationType.AURORA_SOLAR && {
          id: log.raw.agreement.id,
          name: log.raw.agreement.agreement_template_name,
          created_at: log.raw.agreement.created_at,
          status: log.raw.agreement.status
        }) ||
      (log && log.integration.type === ConnectIntegrationType.OPEN_SOLAR && {}) ||
      null,
    [log]
  );

  return (
    <Widget isFullWidth={isFullWidth}>
      <WidgetHeader>
        <WidgetTitle>
          <WidgetIcon backgroundColor="#ECE3FF">
            <Users size="16px" color="#7256AC" />
          </WidgetIcon>
          {title}
        </WidgetTitle>
      </WidgetHeader>

      <Tabs>
        {(['projects', 'proposals', 'contracts'] as const).map((tab) => (
          <Tab key={tab} onClick={() => setActiveTab(tab)} isActive={activeTab === tab}>
            {tab}
          </Tab>
        ))}
      </Tabs>

      {activeTab === 'projects' && (
        <List>
          {externalProject && (
            <Row key={externalProject.id}>
              <Cell>
                <div>{externalProject.name}</div>
                <CellBotton>{externalProject.created_at}</CellBotton>
              </Cell>
              <Cell style={{ alignItems: 'center' }}>
                <Badge color={colors.black} bgColor="#DFDFE8">
                  {enumToHuman(externalProject.status) || '<Unknown>'}
                </Badge>
                <div>&nbsp;</div>
              </Cell>
              <Cell>
                <CellTop>
                  <ConnectIntegrationBrand type={log.integration.type as ConnectIntegrationType} />
                  <a
                    href={getConnectProjectLink(log.integration.type as ConnectIntegrationType, log.raw)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ExternalLink size={16} color={colors.blue} />
                  </a>
                </CellTop>
                <CellBotton>ID: {externalProject.id}</CellBotton>
              </Cell>
            </Row>
          )}
        </List>
      )}

      {activeTab === 'proposals' && (
        <List>
          {externalProposal && (
            <Row key={externalProposal.design_id}>
              <Cell>
                <div>{externalProposal.name}</div>
                <CellBotton>{externalProposal.created_at}</CellBotton>
              </Cell>
              <Cell style={{ alignItems: 'center' }}>
                <Badge color={colors.black} bgColor="#DFDFE8">
                  {enumToHuman(externalProposal.status) || '<Unknown>'}
                </Badge>
                <div>&nbsp;</div>
              </Cell>
              <Cell>
                <CellTop>
                  <ConnectIntegrationBrand type={log.integration.type as ConnectIntegrationType} />
                  <a
                    href={getConnectProposalLink(log.integration.type as ConnectIntegrationType, log.raw)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ExternalLink size={16} color={colors.blue} />
                  </a>
                </CellTop>
                <CellBotton>ID: {externalProposal.design_id}</CellBotton>
              </Cell>
            </Row>
          )}
        </List>
      )}

      {activeTab === 'contracts' && (
        <List>
          {externalContract && (
            <Row key={externalContract.id}>
              <Cell>
                <div>{externalContract.name}</div>
                <CellBotton>{externalContract.created_at}</CellBotton>
              </Cell>
              <Cell style={{ alignItems: 'center' }}>
                <Badge color={colors.black} bgColor="#DFDFE8">
                  {enumToHuman(externalContract.status) || '<Unknown>'}
                </Badge>
                <div>&nbsp;</div>
              </Cell>
              <Cell>
                <CellTop>
                  <ConnectIntegrationBrand type={log.integration.type as ConnectIntegrationType} />
                  <a
                    href={getConnectContractLink(log.integration.type as ConnectIntegrationType, log.raw)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ExternalLink size={16} color={colors.blue} />
                  </a>
                </CellTop>
                <CellBotton>ID: {externalContract.id}</CellBotton>
              </Cell>
            </Row>
          )}
        </List>
      )}
    </Widget>
  );
};

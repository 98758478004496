import React, { FC, useCallback, useState } from 'react';
import { useConnectIntegrationLogs } from '@hooks/workspace/connectIntegration';
import { debounce } from 'lodash/fp';
import { SearchBar } from '@common/SearchBar';
import { Badge, BadgeSize } from '@kit/ui/Badge';
import { CoperniqProjectLink, Header, PagingTotal, statusBgColor, statusColor, Table } from './styled';
import { DateTime } from 'luxon';
import { Box } from '@material-ui/core';
import { useRoutes } from '@hooks';
import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import { capitalize } from 'lodash';
import { colors } from '@styles';
import { ConnectIntegrationName, ConnectIntegrationType } from '@features/Platform/Integrations';

interface HistoryProps {
  integrationId: number;
  integrationType: ConnectIntegrationType;
}

export const IntegrationLog: FC<HistoryProps> = ({ integrationId, integrationType }) => {
  const pageSize = 5;

  const [page, setPage] = useState(1);

  const [search, setSearch] = useState('');

  const { data: logs } = useConnectIntegrationLogs({
    integrationId: integrationId,
    first: page * pageSize,
    search: search || null
  });

  const handleSearchValueChange = useCallback(debounce(300, setSearch), []);

  const { getRecordDetailsPagePath } = useRoutes();

  return (
    <Box display="flex" flexDirection="column" gridGap={16}>
      <Box display="flex" gridGap={80}>
        <Header>History Log</Header>

        <SearchBar placeholder="Search..." onValueChange={handleSearchValueChange} />
      </Box>

      <Table>
        <thead>
          <tr>
            <th>Date & Time</th>
            <th>{ConnectIntegrationName[integrationType]} Project</th>
            <th>Coperniq Project</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {logs.nodes.map((log) => (
            <tr key={log.id}>
              <td>{DateTime.fromISO(log.createdAt).toLocaleString(DateTime.DATETIME_MED)}</td>
              <td>
                {log.raw?.project ? (
                  <>
                    <div>
                      {log.raw.project.name ||
                        log.raw.project.address ||
                        `${log.raw.project.customer_first_name} ${log.raw.project.customer_last_name}`}
                    </div>
                    <div style={{ color: colors.gray }}>ID: {log.raw.project.id}</div>
                  </>
                ) : (
                  <>Unknown</>
                )}
              </td>
              <td>
                {log.project ? (
                  <CoperniqProjectLink to={getRecordDetailsPagePath(log.project)}>
                    {log.project.title} ({log.project.uid})
                  </CoperniqProjectLink>
                ) : (
                  <>Not mapped</>
                )}
              </td>
              <td>
                <Badge size={BadgeSize.Large} color={statusColor[log.status]} bgColor={statusBgColor[log.status]}>
                  {log.status
                    .split('_')
                    .map((word) => capitalize(word))
                    .join(' ')}
                </Badge>
              </td>
            </tr>
          ))}
          <tr className="pagination">
            <td colSpan={4}>
              <Box display="flex" gridGap={16} alignItems="center">
                <Button
                  variant={ButtonVariant.Flat}
                  size={ButtonSize.Small}
                  onClick={() => setPage(page + 1)}
                  disabled={page * pageSize >= logs.totalCount}
                >
                  Show more
                </Button>

                <PagingTotal>
                  {Math.min(1, logs.totalCount)}-{Math.min(page * pageSize, logs.totalCount)} of {logs.totalCount}
                </PagingTotal>
              </Box>
            </td>
          </tr>
        </tbody>
      </Table>
    </Box>
  );
};

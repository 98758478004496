import styled from 'styled-components';
import { colors } from '@styles';
import { ConnectLogStatus } from '@generated/types/graphql';
import { Link } from '@reach/router';

export const Header = styled.div`
  font: ${(props) => props.theme.typefaces.title2};
`;

export const CoperniqProjectLink = styled(Link)`
  font: ${(props) => props.theme.typefaces.pagination};
  color: #235DFF;
`;

export const statusColor: { [status in ConnectLogStatus]: string } = {
  [ConnectLogStatus.Success]: colors.green,
  [ConnectLogStatus.Failed]: colors.red,
  [ConnectLogStatus.InProgress]: colors.blue
};

export const statusBgColor: { [status in ConnectLogStatus]: string } = {
  [ConnectLogStatus.Success]: colors.greenBg,
  [ConnectLogStatus.Failed]: colors.redBg,
  [ConnectLogStatus.InProgress]: colors.blueBg
};

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;

  thead {
    th {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font: ${(props) => props.theme.typefaces.text};
      color: #828D9A;
      height: 40px;
      text-align: left;
      padding: 12px 16px 12px 16px;
      text-transform: uppercase;
    }
  }

  tbody {
    tr {
      height: 60px;
      background-color: ${colors.white};

      &.pagination {
        height: 38px;

        td {
          text-align: left;
          padding: 8px;
        }
      }
    }

    td {
      overflow: hidden;
      word-break: break-word;
      font: ${(props) => props.theme.typefaces.pagination};
      padding: 12px 16px 12px 16px;
      text-align: left;
      border-bottom: 1px solid #DFDFE8;
    }

    td:first-child {
      border-left: 1px solid #DFDFE8;
    }

    td:last-child {
      border-right: 1px solid #DFDFE8;
    }

    tr:first-child td {
      border-top: 1px solid #DFDFE8;
    }

    tr:last-child td {
      border-bottom: 1px solid #DFDFE8;
    }

    tr:first-child td:first-child {
      border-top-left-radius: 8px;
    }

    tr:first-child td:last-child {
      border-top-right-radius: 8px;
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 8px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 8px;
    }
  }
}
`;

export const PagingTotal = styled.div`
  font: ${(props) => props.theme.typefaces.pagination};
  color: #828D9A;
`;

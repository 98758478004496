import { deleteRequest, get, post, put } from '@services/api/base';
import {
  ConnectColumnMappingDTO,
  CreateAuroraIntegrationDTO,
  CreateOpenSolarIntegrationDTO,
  MappingOption,
  UpdateConnectIntegrationDTO
} from '@features/Platform/Integrations';
import { ConnectIntegration } from '@generated/types/graphql';
import { Urls } from '@services/api/base/axios';

const BASE_URL = `${Urls.connect}/connect`;

export const connectApi = {
  createAuroraIntegration: (dto: CreateAuroraIntegrationDTO) => post<ConnectIntegration>(`${BASE_URL}/aurora`, dto),

  createOpenSolarIntegration: (dto: CreateOpenSolarIntegrationDTO) => post<ConnectIntegration>(`${BASE_URL}/opensolar`, dto),

  updateIntegration: (id: number, dto: UpdateConnectIntegrationDTO) =>
    put<ConnectIntegration>(`${BASE_URL}/${id}`, dto),

  deleteIntegration: (id: number) => deleteRequest(`${BASE_URL}/${id}`),

  getColumnsMappingOptions: (integrationId: number) =>
    get<MappingOption[]>(`${BASE_URL}/${integrationId}/column-mapping/options`),

  createColumnMapping: (integrationId: number, dto: ConnectColumnMappingDTO) =>
    post(`${BASE_URL}/${integrationId}/column-mapping`, dto),

  deleteColumnMapping: (integrationId: number, columnId: number) =>
    deleteRequest(`${BASE_URL}/${integrationId}/column-mapping`, undefined, { columnId })
};

import React from 'react';
import { Link } from 'gatsby';
import { ChevronRightIcon } from '@kit/ui/icons/ChevronRight';
import { useConnectIntegration } from '@hooks/workspace/connectIntegration';
import { Breadcrumbs, Container, Description, Header, SectionContainer, Title } from './styled';
import { ConnectIntegrationType } from '@features/Platform/Integrations';
import {
  CustomPropertiesMapping,
  IntegrationLog,
  MatchingStrategySelect,
  StandardPropertiesMapping,
  WorkflowSelect
} from '../Common';

interface Props {
  connectIntegrationId: string;
}

export const OpenSolarIntegration = ({ connectIntegrationId }: Props) => {
  const { data: integration } = useConnectIntegration({ id: Number(connectIntegrationId) });

  if (!integration) {
    return null;
  }

  return (
    <Container>
      <Header>
        <Breadcrumbs>
          <Link to="../..">Integrations</Link>
          <ChevronRightIcon size="16px" color="#828D9A" />
          <div>OpenSolar</div>
        </Breadcrumbs>

        <Title>OpenSolar</Title>
        <Description>
          Get seamless access to solar design data from OpenSolar, enabling automated data transfers and enhanced project
          management within your platform.
        </Description>
      </Header>

      <IntegrationLog integrationId={integration.id} integrationType={integration.type as ConnectIntegrationType} />

      <SectionContainer>
        <WorkflowSelect integration={integration} />
      </SectionContainer>

      <SectionContainer>
        <StandardPropertiesMapping
          integrationType={integration.type as ConnectIntegrationType}
          mappings={integration.connectColumnMappingsByIntegrationId}
        />
      </SectionContainer>

      <SectionContainer>
        <CustomPropertiesMapping
          integrationId={integration.id}
          integrationType={integration.type as ConnectIntegrationType}
          mappings={integration.connectColumnMappingsByIntegrationId}
        />
      </SectionContainer>

      <SectionContainer>
        <MatchingStrategySelect integration={integration} />
      </SectionContainer>
    </Container>
  );
};
import { AnalyticsWidgetDateRangeType } from '@features/Analytics/types';
import { DatetimeFilter } from '@generated/types/graphql';
import { FilterOperators } from '@types';
import {
  isButtonOrLink,
  isDate,
  isTimeRangeDays,
  isDropdown,
  isFiles,
  isNumeric,
  isPerson,
  isText
} from '@utils/properties';
import { DeepPartial } from 'redux';
import { DateTime } from 'luxon';
import { dateRangeConfig } from '@features/Analytics/dateRangeConfig';
import { InternalConfigutationError } from '../../../InternalConfigurationError';
import { FilterHandlerFn } from './types';
import { handlePersonFilterValue } from '../helpers';

const buildLikeFilter: FilterHandlerFn = ({ property, filter }) => {
  // scope: [isRegularText]
  const propertyName = property.name;

  if (!isText(property) && !isButtonOrLink(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value) {
    return {
      or: [
        {
          projectPropertiesValues: {
            none: {
              columnId: {
                equalTo: property.id as number
              }
            }
          }
        },
        {
          projectPropertiesValues: {
            some: {
              columnId: {
                equalTo: property.id as number
              },
              textValue: {
                isNull: true
              }
            }
          }
        },
        {
          projectPropertiesValues: {
            some: {
              columnId: {
                equalTo: property.id as number
              },
              textValue: {
                equalTo: ''
              }
            }
          }
        }
      ]
    };
  }

  return {
    projectPropertiesValues: {
      some: {
        columnId: {
          equalTo: property.id as number
        },
        textValue: {
          includesInsensitive: filter.value as string
        }
      }
    }
  };
};

const buildNotEqualToFilter: FilterHandlerFn = ({ property, filter }) => {
  // scope: [isRegularText, PropertyType.Date, PropertyType.DateTime, PropertyType.Numeric]
  const propertyName = property.name;

  if (
    !isText(property) &&
    !isButtonOrLink(property) &&
    !isDate(property) &&
    !isNumeric(property) &&
    !isTimeRangeDays(property)
  ) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value) {
    if (isNumeric(property) || isTimeRangeDays(property)) {
      return {
        not: {
          or: [
            {
              projectPropertiesValues: {
                some: {
                  columnId: {
                    equalTo: property.id as number
                  },
                  ...(isNumeric(property)
                    ? {
                        numericValue: {
                          isNull: true
                        }
                      }
                    : {}),
                  ...(isTimeRangeDays(property)
                    ? {
                        timeRangeValue: {
                          isNull: true
                        }
                      }
                    : {})
                }
              }
            },
            {
              projectPropertiesValues: {
                none: {
                  columnId: {
                    equalTo: property.id as number
                  }
                }
              }
            }
          ]
        }
      };
    }

    if (isDate(property)) {
      return {
        not: {
          or: [
            {
              projectPropertiesValues: {
                some: {
                  columnId: {
                    equalTo: property.id as number
                  },
                  dateValue: {
                    isNull: true
                  }
                }
              }
            },
            {
              projectPropertiesValues: {
                none: {
                  columnId: {
                    equalTo: property.id as number
                  }
                }
              }
            }
          ]
        }
      };
    }

    return {
      not: {
        or: [
          {
            projectPropertiesValues: {
              none: {
                columnId: {
                  equalTo: property.id as number
                }
              }
            }
          },
          {
            projectPropertiesValues: {
              some: {
                columnId: {
                  equalTo: property.id as number
                },
                textValue: {
                  isNull: true
                }
              }
            }
          },
          {
            projectPropertiesValues: {
              some: {
                columnId: {
                  equalTo: property.id as number
                },
                textValue: {
                  equalTo: ''
                }
              }
            }
          }
        ]
      }
    };
  }

  if (isNumeric(property) || isTimeRangeDays(property)) {
    return {
      or: [
        {
          projectPropertiesValues: {
            some: {
              columnId: {
                equalTo: property.id as number
              },
              ...(isNumeric(property)
                ? {
                    numericValue: {
                      notEqualTo: parseFloat(filter.value as string)
                    }
                  }
                : {}),
              ...(isTimeRangeDays(property)
                ? {
                    timeRangeDaysValue: {
                      notEqualTo: parseFloat(filter.value as string)
                    }
                  }
                : {})
            }
          }
        },
        {
          projectPropertiesValues: {
            some: {
              columnId: {
                equalTo: property.id as number
              },
              numericValue: {
                isNull: true
              }
            }
          }
        }
      ]
    };
  }

  if (isDate(property)) {
    const dateTime = DateTime.fromISO(filter.value as string);

    const datetimeFilter: DeepPartial<DatetimeFilter> = {
      greaterThanOrEqualTo: dateTime.startOf('day').toISO(),
      lessThanOrEqualTo: dateTime.endOf('day').toISO()
    };

    return {
      or: [
        {
          not: {
            projectPropertiesValues: {
              some: {
                columnId: {
                  equalTo: property.id as number
                },
                dateValue: datetimeFilter
              }
            }
          }
        },
        {
          projectPropertiesValues: {
            some: {
              columnId: {
                equalTo: property.id as number
              },
              dateValue: {
                isNull: true
              }
            }
          }
        }
      ]
    };
  }

  return {
    or: [
      {
        projectPropertiesValues: {
          some: {
            columnId: {
              equalTo: property.id as number
            },
            textValue: {
              notIncludesInsensitive: filter.value as string
            }
          }
        }
      },
      {
        projectPropertiesValues: {
          some: {
            columnId: {
              equalTo: property.id as number
            },
            textValue: {
              isNull: true
            }
          }
        }
      }
    ]
  };
};

const buildEqualToFilter: FilterHandlerFn = ({ property, filter }) => {
  // scope: [PropertyType.Date, PropertyType.DateTime, PropertyType.Numeric, isWorkOrderTypeField]
  const propertyName = property.name;

  if (!isDate(property) && !isNumeric(property) && !isTimeRangeDays(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value) {
    if (isNumeric(property) || isTimeRangeDays(property)) {
      return {
        or: [
          {
            projectPropertiesValues: {
              some: {
                columnId: {
                  equalTo: property.id as number
                },
                ...(isNumeric(property)
                  ? {
                      numericValue: {
                        isNull: true
                      }
                    }
                  : {}),
                ...(isTimeRangeDays(property)
                  ? {
                      timeRangeValue: {
                        isNull: true
                      }
                    }
                  : {})
              }
            }
          },
          {
            projectPropertiesValues: {
              none: {
                columnId: {
                  equalTo: property.id as number
                }
              }
            }
          }
        ]
      };
    }

    if (isDate(property)) {
      return {
        or: [
          {
            projectPropertiesValues: {
              some: {
                columnId: {
                  equalTo: property.id as number
                },
                dateValue: {
                  isNull: true
                }
              }
            }
          },
          {
            projectPropertiesValues: {
              none: {
                columnId: {
                  equalTo: property.id as number
                }
              }
            }
          }
        ]
      };
    }

    return {};
  }

  if (isDate(property)) {
    const dateTime = DateTime.fromISO(filter.value as string);

    const datetimeFilter: DeepPartial<DatetimeFilter> = {
      greaterThanOrEqualTo: dateTime.startOf('day').toISO(),
      lessThanOrEqualTo: dateTime.endOf('day').toISO()
    };

    return {
      projectPropertiesValues: {
        some: {
          columnId: {
            equalTo: property.id as number
          },
          dateValue: datetimeFilter
        }
      }
    };
  }

  return {
    projectPropertiesValues: {
      some: {
        columnId: {
          equalTo: property.id as number
        },
        ...(isNumeric(property)
          ? {
              numericValue: {
                equalTo: parseFloat(filter.value as string)
              }
            }
          : {}),
        ...(isTimeRangeDays(property)
          ? {
              timeRangeDaysValue: {
                equalTo: parseFloat(filter.value as string)
              }
            }
          : {})
      }
    }
  };
};

const buildInFilter: FilterHandlerFn = ({ property, filter, teamsMap }) => {
  const propertyName = property.name;

  // scope: [PropertyType.Person, isStageProperty, isRegularSingleDropdown, isWorkflowField, isWorkOrderTemplateField]
  if (!isPerson(property) && !(isDropdown(property) && !property.multiple)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value || !filter.value.length) {
    if (isPerson(property)) {
      return {
        projectPropertiesValues: {
          some: {
            columnId: {
              equalTo: property.id as number
            },
            workerValue: {
              isNull: false
            }
          }
        }
      };
    }

    return {
      projectPropertiesValues: {
        some: {
          columnId: {
            equalTo: property.id as number
          },
          dropdownValue: {
            isNull: false
          }
        }
      }
    };
  }

  if (isPerson(property)) {
    return {
      projectPropertiesValues: {
        some: {
          columnId: {
            equalTo: property.id as number
          },
          workerValue: {
            in: handlePersonFilterValue(filter.value as number[], teamsMap)
          }
        }
      }
    };
  }

  return {
    projectPropertiesValues: {
      some: {
        columnId: {
          equalTo: property.id as number
        },
        dropdownValue: {
          containedBy: filter.value as string[]
        }
      }
    }
  };
};

const buildNotInFilter: FilterHandlerFn = ({ property, filter, teamsMap }) => {
  const propertyName = property.name;

  // scope: [PropertyType.Person, isStageProperty, isRegularSingleDropdown, isWorkflowField, isWorkOrderTemplateField]
  if (!isPerson(property) && !(isDropdown(property) && !property.multiple)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value || !filter.value.length) {
    if (isPerson(property)) {
      return {
        or: [
          {
            projectPropertiesValues: {
              none: {
                columnId: {
                  equalTo: property.id as number
                }
              }
            }
          },
          {
            projectPropertiesValues: {
              some: {
                columnId: {
                  equalTo: property.id as number
                },
                workerValue: {
                  isNull: true
                }
              }
            }
          }
        ]
      };
    }

    return {
      or: [
        {
          projectPropertiesValues: {
            none: {
              columnId: {
                equalTo: property.id as number
              }
            }
          }
        },
        {
          projectPropertiesValues: {
            some: {
              columnId: {
                equalTo: property.id as number
              },
              dropdownValue: {
                isNull: true
              }
            }
          }
        }
      ]
    };
  }

  if (isPerson(property)) {
    return {
      or: [
        {
          projectPropertiesValues: {
            none: {
              columnId: {
                equalTo: property.id as number
              }
            }
          }
        },
        {
          projectPropertiesValues: {
            some: {
              columnId: {
                equalTo: property.id as number
              },
              workerValue: {
                isNull: true
              }
            }
          }
        },
        {
          projectPropertiesValues: {
            some: {
              columnId: {
                equalTo: property.id as number
              },
              workerValue: {
                notIn: handlePersonFilterValue(filter.value as number[], teamsMap)
              }
            }
          }
        }
      ]
    };
  }

  return {
    not: {
      projectPropertiesValues: {
        some: {
          columnId: {
            equalTo: property.id as number
          },
          dropdownValue: {
            containedBy: filter.value as string[]
          }
        }
      }
    }
  };
};

const buildContainsFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = property.name;

  // scope: [isRegularMultipleDropdown]
  if (!isDropdown(property) || !property.multiple) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value || !filter.value.length) {
    return {
      projectPropertiesValues: {
        some: {
          columnId: {
            equalTo: property.id as number
          },
          dropdownValue: {
            isNull: false
          }
        }
      }
    };
  }

  return {
    projectPropertiesValues: {
      some: {
        columnId: {
          equalTo: property.id as number
        },
        dropdownValue: {
          overlaps: filter.value as string[]
        }
      }
    }
  };
};

const buildNotContainsFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = property.name;

  // scope: [isRegularMultipleDropdown]
  if (!isDropdown(property) || !property.multiple) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value || !filter.value.length) {
    return {
      or: [
        {
          projectPropertiesValues: {
            none: {
              columnId: {
                equalTo: property.id as number
              }
            }
          }
        },
        {
          projectPropertiesValues: {
            some: {
              columnId: {
                equalTo: property.id as number
              },
              dropdownValue: {
                isNull: true
              }
            }
          }
        }
      ]
    };
  }

  return {
    not: {
      projectPropertiesValues: {
        some: {
          columnId: {
            equalTo: property.id as number
          },
          dropdownValue: {
            overlaps: filter.value as string[]
          }
        }
      }
    }
  };
};

const buildContainedByFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = property.name;

  // scope: [isRegularMultipleDropdown]
  if (!isDropdown(property) || !property.multiple) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value || !filter.value.length) {
    return {
      or: [
        {
          projectPropertiesValues: {
            none: {
              columnId: {
                equalTo: property.id as number
              }
            }
          }
        },
        {
          projectPropertiesValues: {
            some: {
              columnId: {
                equalTo: property.id as number
              },
              dropdownValue: {
                isNull: true
              }
            }
          }
        }
      ]
    };
  }

  return {
    projectPropertiesValues: {
      some: {
        columnId: {
          equalTo: property.id as number
        },
        dropdownValue: {
          contains: filter.value as string[]
        }
      }
    }
  };
};

const buildNotContainedByFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = property.name;

  // scope: [isRegularMultipleDropdown]
  if (!isDropdown(property) || !property.multiple) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value || !filter.value.length) {
    return {
      projectPropertiesValues: {
        some: {
          columnId: {
            equalTo: property.id as number
          },
          dropdownValue: {
            isNull: false
          }
        }
      }
    };
  }

  return {
    not: {
      projectPropertiesValues: {
        some: {
          columnId: {
            equalTo: property.id as number
          },
          dropdownValue: {
            contains: filter.value as string[]
          }
        }
      }
    }
  };
};

const buildLessThanFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = property.name;

  // scope: [PropertyType.Numeric]
  // scope: [PropertyType.Date] // this is only for old smart views which were not properly migrated
  if (!isNumeric(property) && !isDate(property) && !isTimeRangeDays(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value) {
    return {};
  }

  if (isDate(property)) {
    return buildBeforeFilter({ property, filter });
  }

  return {
    projectPropertiesValues: {
      some: {
        columnId: {
          equalTo: property.id as number
        },
        ...(isNumeric(property)
          ? {
              numericValue: {
                lessThan: parseFloat(filter.value as string)
              }
            }
          : {}),
        ...(isTimeRangeDays(property)
          ? {
              timeRangeDaysValue: {
                lessThan: parseFloat(filter.value as string)
              }
            }
          : {})
      }
    }
  };
};

const buildGreaterThanFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = property.name;

  // scope: [PropertyType.Numeric]
  // scope: [PropertyType.Date] // this is only for old smart views which were not properly migrated
  if (!isNumeric(property) && !isDate(property) && !isTimeRangeDays(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value) {
    return {};
  }

  if (isDate(property)) {
    return buildAfterFilter({ property, filter });
  }

  return {
    projectPropertiesValues: {
      some: {
        columnId: {
          equalTo: property.id as number
        },
        ...(isNumeric(property)
          ? {
              numericValue: {
                greaterThan: parseFloat(filter.value as string)
              }
            }
          : {}),
        ...(isTimeRangeDays(property)
          ? {
              timeRangeDaysValue: {
                greaterThan: parseFloat(filter.value as string)
              }
            }
          : {})
      }
    }
  };
};

const buildBeforeFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = property.name;

  // scope: [PropertyType.Date], [PropertyType.DateTime]
  if (!isDate(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value) {
    return {};
  }

  const dateTime = DateTime.fromISO(filter.value as string);

  const datetimeFilter: DeepPartial<DatetimeFilter> = {
    lessThan: dateTime.startOf('day').toISO()
  };

  return {
    projectPropertiesValues: {
      some: {
        columnId: {
          equalTo: property.id as number
        },
        dateValue: datetimeFilter
      }
    }
  };
};

const buildAfterFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = property.name;

  // scope: [PropertyType.Date], [PropertyType.DateTime]
  if (!isDate(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value) {
    return {};
  }

  const dateTime = DateTime.fromISO(filter.value as string);

  const datetimeFilter: DeepPartial<DatetimeFilter> = {
    greaterThan: dateTime.endOf('day').toISO()
  };

  return {
    projectPropertiesValues: {
      some: {
        columnId: {
          equalTo: property.id as number
        },
        dateValue: datetimeFilter
      }
    }
  };
};

const buildWithinFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = property.name;

  // scope: [PropertyType.Date], [PropertyType.DateTime]
  if (!isDate(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value) {
    return {};
  }

  const dateRangeOption = dateRangeConfig[filter.value as AnalyticsWidgetDateRangeType];

  if (!dateRangeOption) {
    throw new InternalConfigutationError(`Unsupported date range type ${filter.value}`);
  }

  const datetimeFilter: DeepPartial<DatetimeFilter> = {
    greaterThanOrEqualTo: dateRangeOption.startDate().toISO(),
    lessThanOrEqualTo: dateRangeOption.endDate().toISO()
  };

  return {
    projectPropertiesValues: {
      some: {
        columnId: {
          equalTo: property.id as number
        },
        dateValue: datetimeFilter
      }
    }
  };
};

const buildNotWithinFilter: FilterHandlerFn = ({ property, filter }) => {
  const propertyName = property.name;

  // scope: [PropertyType.Date], [PropertyType.DateTime]
  if (!isDate(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  if (!filter.value) {
    return {};
  }

  const dateRangeOption = dateRangeConfig[filter.value as AnalyticsWidgetDateRangeType];

  if (!dateRangeOption) {
    throw new InternalConfigutationError(`Unsupported date range type ${filter.value}`);
  }

  const datetimeFilter: DeepPartial<DatetimeFilter> = {
    greaterThanOrEqualTo: dateRangeOption.startDate().toISO(),
    lessThanOrEqualTo: dateRangeOption.endDate().toISO()
  };

  return {
    not: {
      projectPropertiesValues: {
        some: {
          columnId: {
            equalTo: property.id as number
          },
          dateValue: datetimeFilter
        }
      }
    }
  };
};

const buildIsEmptyFilter: FilterHandlerFn = ({ property }) => {
  const propertyName = property.name;

  // scope: [PropertyType.File]
  if (!isFiles(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  return {
    or: [
      {
        projectPropertiesValues: {
          some: {
            columnId: {
              equalTo: property.id as number
            },
            fileValuesIndirectExist: false
          }
        }
      },
      {
        projectPropertiesValues: {
          none: {
            columnId: {
              equalTo: property.id as number
            }
          }
        }
      }
    ]
  };
};

const buildIsNotEmptyFilter: FilterHandlerFn = ({ property }) => {
  const propertyName = property.name;

  // scope: [PropertyType.File]
  if (!isFiles(property)) {
    throw new InternalConfigutationError(`Unsupported filter operator for ${propertyName}`);
  }

  return {
    projectPropertiesValues: {
      some: {
        columnId: {
          equalTo: property.id as number
        },
        fileValuesIndirectExist: true
      }
    }
  };
};

export const customPropertiesFilterHandlers: Record<FilterOperators, FilterHandlerFn> = {
  [FilterOperators.Like]: buildLikeFilter,
  [FilterOperators.NotEqualTo]: buildNotEqualToFilter,
  [FilterOperators.EqualTo]: buildEqualToFilter,
  [FilterOperators.In]: buildInFilter,
  [FilterOperators.NotIn]: buildNotInFilter,
  [FilterOperators.Contains]: buildContainsFilter,
  [FilterOperators.NotContains]: buildNotContainsFilter,
  [FilterOperators.ContainedBy]: buildContainedByFilter,
  [FilterOperators.NotContainedBy]: buildNotContainedByFilter,
  [FilterOperators.LessThan]: buildLessThanFilter,
  [FilterOperators.GreaterThan]: buildGreaterThanFilter,
  [FilterOperators.Before]: buildBeforeFilter,
  [FilterOperators.After]: buildAfterFilter,
  [FilterOperators.Within]: buildWithinFilter,
  [FilterOperators.NotWithin]: buildNotWithinFilter,
  [FilterOperators.IsEmpty]: buildIsEmptyFilter,
  [FilterOperators.IsNotEmpty]: buildIsNotEmptyFilter
};

import { ImportMatchFoundStrategy, Property } from '@types';

export type CreateAuroraIntegrationDTO = {
  token: string;
  tenant: string;
};

export type CreateOpenSolarIntegrationDTO = {
  username: string;
  password: string;
  mfaToken?: string;
};

export type UpdateConnectIntegrationDTO = {
  matchFoundStrategy?: ImportMatchFoundStrategy;
  blueprintId?: number | null;
  matchFallbackProjectColumnId?: number;
};

export type ConnectColumnMappingDTO = {
  columnId: number;
  valuePath: string;
};

export enum ConnectIntegrationType {
  AURORA_SOLAR = 'AURORA_SOLAR',
  OPEN_SOLAR = 'OPEN_SOLAR'
}

export type MappingOption = {
  column: Omit<Property, 'id'>;
  /**
   * Full path to the field in the integration data blob, like `project.name` or `consumptionProfile.efficiency_packages[0].efficiency_package_name`
   */
  path: string;
};

import { ConnectIntegrationType } from '@features/Platform/Integrations/types';

export const getConnectProjectLink = (integrationType: ConnectIntegrationType, rawOrId: string | any) => {
  switch (integrationType) {
    case ConnectIntegrationType.AURORA_SOLAR:
      return `https://v2.aurorasolar.com/projects/${typeof rawOrId === 'string' ? rawOrId : rawOrId.project.id}/overview/dashboard`;

    case ConnectIntegrationType.OPEN_SOLAR:
      return `TODO`; // TODO

    default:
      throw new Error(`Unrecognized integration type ${integrationType satisfies never}`);
  }
};

export const getConnectProposalLink = (integrationType: ConnectIntegrationType, raw: any) => {
  switch (integrationType) {
    case ConnectIntegrationType.AURORA_SOLAR:
      return `https://v2-sandbox.aurorasolar.com/projects/${raw.project.id}/designs/${raw.design.id}`;

    case ConnectIntegrationType.OPEN_SOLAR:
      return `TODO`; // TODO

    default:
      throw new Error(`Unrecognized integration type ${integrationType satisfies never}`);
  }
};

export const getConnectContractLink = (integrationType: ConnectIntegrationType, raw: any) => {
  switch (integrationType) {
    case ConnectIntegrationType.AURORA_SOLAR:
      return raw.agreementLink.file_url;

    case ConnectIntegrationType.OPEN_SOLAR:
      return `TODO`; // TODO

    default:
      throw new Error(`Unrecognized integration type ${integrationType satisfies never}`);
  }
};

export const ConnectIntegrationName: { [type in ConnectIntegrationType]: string } = {
  [ConnectIntegrationType.AURORA_SOLAR]: 'Aurora Solar',
  [ConnectIntegrationType.OPEN_SOLAR]: 'OpenSolar'
};

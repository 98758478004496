export enum ReactQueryKey {
  AgendaUnscheduledTasks = 'agenda-unscheduled-tasks',
  Analytics = 'chart-analytics',
  AnalyticsData = 'analytics-data',
  AnalyticsDataPreview = 'analytics-data-preview',
  CompanyUsers = 'company-users',
  CompanyMembersList = 'company-members-list',
  CompanyPhones = 'company-phones',
  CompanyEmailAccounts = 'company-email-accounts',
  Dashboards = 'dashboards',
  DealStages = 'deal-stages',
  DynamicDropdownValues = 'dynamic-dropdown-values',
  ProjectActivity = 'project-activity',
  ProjectMembers = 'project-members',
  ProjectWorkflows = 'project-workflows',
  AutomationTriggersAndActions = 'automation-triggers-and-actions',
  WorkspaceAnalytics = 'workspace-analytics',
  FormTemplate = 'form-template',
  FormTemplates = 'form-templates',
  PaymentMethods = 'payment-methods',
  SmartViews = 'smart-views', // old
  SmartViewList = 'smart-view-list',
  SmartViewFolderList = 'smart-view-folder-list',
  TaskTimeTracks = 'task-time-tracks',
  UnscheduledTasksFilterOptions = 'unsheduled-tasks-filter-options',
  ProjectStages = 'project-stages',
  CompanyProperties = 'company-properties',
  TaskTemplate = 'task-template',
  Tasks = 'tasks',
  TasksTray = 'tasks-tray',
  TasksScheduler = 'tasks-scheduler',
  TasksDispatcher = 'tasks-dispatcher',
  TasksList = 'tasks-list',
  TasksDetails = 'tasks-details',
  TasksWork = 'tasks-work',

  TasksWidget = 'tasks-widget',
  TasksInSites = 'tasks-in-sites',
  SchedulerTrayTasks = 'scheduler-tray-tasks',
  DispatcherTrayTasks = 'dispatcher-tray-tasks',
  DispatcherMapUndispatchedTasks = 'dispatcher-map-undispatched-tasks',
  WorkspaceStages = 'workspace-stages',
  WorkspaceStagesGql = 'workspace-stages-graphql',
  WorkspaceBlueprints = 'workspace-blueprints',
  Notifications = 'notifications',
  UnreadNotificationCount = 'unread-notification-count',
  WorkspaceRoles = 'workspace-roles',
  PropertyMappedName = 'property-mapped-name',
  WorkspaceContacts = 'workspace-contacts',
  WorkspaceAccounts = 'workspace-accounts',
  RecordDetail = 'record-detail',
  RecordReminders = 'record-reminders',
  ListViewReminders = 'list-view-reminders',
  WorkspaceProjects = 'workspace-projects',
  WorkspaceDeals = 'workspace-deals',
  WorkspaceEmailAccounts = 'workspace-email-accounts',
  Inbox = 'inbox',
  InboxNotifications = 'inbox-notifications',
  InboxNotificationsById = 'inbox-notifications-by-id',
  InboxNotificationsUnreadCounter = 'inbox-notifications-unread-counter',
  InboxUnreadCount = 'inbox-unread-count',
  WorkspaceTaskStages = 'workspace-task-stages',
  WidgetData = 'widget-data',
  WorkspaceTeams = 'workspace-teams',
  DatabankTree = 'databank-tree',
  DatabankBreadcrumb = 'databank-breadcrumb',
  StorageInfo = 'storage-info',
  Form = 'form',
  FormProgress = 'form-progress',
  FormFull = 'form-full',
  PropertyGroups = 'property-group',
  File = 'file',
  Catalog = 'catalog',
  CatalogItem = 'catalog-item',
  LineItem = 'line-item',
  TaskStatuses = 'task-statuses',
  TaskStatusesTimelines = 'task-statuses-timelines',
  Files = 'files',
  FilesWidget = 'files-widget',
  FileDetails = 'files-details',
  PropertyFileById = 'property-file-by-id',
  ProjectFolders = 'files-project-folders',
  ProjectsListInitialGroupData = 'projects-list-initial-group-data',
  ProjectsByIds = 'projects-list-by-ids',
  DispatcherActivity = 'dispatcher-activity',
  WorkOrderActivity = 'workorder-activity',
  SocketUpdatedTasks = 'socket-updated-tasks',
  WorkOrderTemplateList = 'workorder-template-list',
  FormTemplateList = 'form-template-list',
  ActionTemplateList = 'action-template-list',
  WorkflowList = 'workflow-list',
  WorkflowPreCreateWorkOrderInfo = 'workflow-pre-create-workorder-info',
  ClientsByIds = 'clients-list-by-ids',
  ClientsListInitialGroupData = 'clients-list-initial-group-data',
  RequestsByIds = 'requests-list-by-ids',
  RequestsListInitialGroupData = 'requests-list-initial-group-data',
  InvoiceList = 'invoice-list',
  RecordStageList = 'record-stage-list',
  RecordDocList = 'record-doc-list',
  ImportHistory = 'import-history',
  ImportHistoryItem = 'import-history-item',
  OneSchemaToken = 'one-schema-token',
  Integration = 'integration',
  SystemsSettings = 'systems-settings',
  FleetsSettings = 'fleets-settings',
  SystemsAlertsSettings = 'systems-alerts-settings',
  SystemsBreakdownHits = 'systems-breakdown-hits',
  SystemsBudget = 'systems-budget',
  ConnectIntegration = 'connect-integration',
  ConnectIntegrationLog = 'connect-integration-log',
  ConnectIntegrationSchema = 'connect-integration-schema',
  System = 'system',
  SystemActivity = 'system-activity',
  WorkspaceNotificationsConfig = 'workspace-notifications-config',
  WorkspacePortalNotificationsConfig = 'workspace-portal-notifications-config',
  WorkspaceNotifications = 'workspace-notifications',
  ActionItemList = 'action-item-list',
  Geolocation = 'geolocation',
  LabelList = 'label-list',
  PinnedComments = 'pinned-comments',
  GeolocationSettings = 'geolocation-settings',
  TimezoneList = 'timezone-list',
  ProjectWork = 'project-work',
  SystemWork = 'system-work',
  WorkflowConfirmChangesWorkOrderList = 'workflow-confirm-changes-work-order-list'
}

import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { ConnectLog, ConnectLogsConnection } from '@generated/types/graphql';

export const useConnectProject = (args: { projectId: number }) =>
  useQuery<ConnectLog | null>(
    [ReactQueryKey.ConnectIntegrationLog, 'useConnectIntegrationHistoryByProject', args],
    async () => {
      try {
        return (
          (
            await postGraphql<{ connectLogsConnection: ConnectLogsConnection }>(
              gql`
                query CONNECT_LOG_LATEST_QUERY($projectId: Int!) {
                  connectLogsConnection(
                    condition: { projectId: $projectId, status: SUCCESS }
                    first: 1
                    orderBy: [CREATED_AT_DESC]
                  ) {
                    nodes {
                      id
                      raw
                      integration {
                        id
                        type
                      }
                    }
                  }
                }
              `,
              args
            )
          ).connectLogsConnection.nodes[0] || null
        );
      } catch (e) {
        throw apiErrorHandler('Error fetching connect log for project', e);
      }
    },
    {
      initialData: null
    }
  );
